/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/no-distracting-elements */
// import { Helmet } from "react-helmet";
import React, { useEffect, useState } from "react";

import "./style.css";
// import ForexFeed from "./ForexFeed";
import Faq from "./Faq";
import Pools from "./Pools";

import Logo from "./images/wingldLogo.webp";
import Banner from "./images/banner.webp";
import Dollar from "./images/dollar-symbol.webp";
import Arrowright from "./images/arrow-right.png";
import AboutImg from "./images/aitrade.webp";
import AItraing from "./images/aitrading.webp";
import automatic from "./images/automatic-trading.webp";
import Trading from "./images/Safe-Trading.webp";
import Accuracy from "./images/Better-Accuracy.webp";
import AItrade from "./images/bot.webp";
import Exness from "./images/exness.webp";
import ProfitShare from "./images/ProfitShare.webp";
import useAuth from "src/hooks/useAuth";
import useExnessInfo from "src/hooks/useExnessInfo";
// import axiosInstance from "src/utils/axios";

const Index = () => {
  const { isAuthenticated } = useAuth();
  const { exnessInfo, loading } = useExnessInfo();

  const [isPopupVisible, setIsPopupVisible] = useState(false);

  // Function to handle button click
  const handleButtonClick = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  // Function to close the popup
  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };

  const [copySuccess, setCopySuccess] = useState(""); // State to manage the copy success message

  const copyToClipboard = () => {
    const partnershipCode = "0cb4xmh5vc";
    navigator.clipboard.writeText(partnershipCode).then(
      () => {
        setCopySuccess("Code copied to clipboard!");
        setTimeout(() => setCopySuccess(""), 3000); // Clear the message after 3 seconds
      },
      () => {
        setCopySuccess("Failed to copy the code.");
        setTimeout(() => setCopySuccess(""), 3000); // Clear the message after 3 seconds
      }
    );
  };

  return (
    <>
      {/* <Helmet> <script src="./js/forex.js"></script> </Helmet> */}
      <div className="landingBody">
        <div className="radialbg1"></div>
        <div className="navTopBarContainer">
          <div className="landingContainer">
            <div className="navTopBarContainerSub">
              {/* {!loading && exnessInfo && (
              <a
                target="_blank"
                href="https://my.exness.com/accounts/sign-up/0cb4xmh5vc"
                className="text_button"
              >
                Exness Registration
              </a>
            )} */}
              <a className="text_button" onClick={handleButtonClick}>
                Exness Registration
              </a>

              {isPopupVisible && (
                <div className="popup">
                  <div className="popup-content">
                    <span className="close" onClick={handleClosePopup}>
                      &times;
                    </span>
                    <div className="popupSubCode">
                      <div className="popupHeading">Exness Registration</div>

                      <div className="popupsubHeading">Partner Code</div>
                      <div className="partnershipcodeBox">
                        <div className="partnershipcode">0cb4xmh5vc</div>
                        <div className="copyButton" onClick={copyToClipboard}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="none"
                            viewBox="0 0 20 20"
                            stroke="currentColor"
                          >
                            <path
                              d="M16 2H8a2 2 0 00-2 2v10a2 2 0 002 2h8a2 2 0 002-2V4a2 2 0 00-2-2z"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                            <path
                              d="M4 8h2v10a2 2 0 002 2h8v2H8a4 4 0 01-4-4V8z"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                            />
                          </svg>
                        </div>
                        {copySuccess && (
                          <div className="copyMessage">{copySuccess}</div>
                        )}
                      </div>

                      <div className="PopupregisterBox">
                        <a
                          target="_blank"
                          href="https://my.exness.com/accounts/sign-up/0cb4xmh5vc"
                          className="text_button2"
                        >
                          Register to Exness
                        </a>
                        <a
                          target="_blank"
                          href={exnessInfo?.website_url}
                          className="text_button2"
                        >
                          Install to Exness Social & upload KYC
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* <a
                target="_blank"
                href="https://my.exness.com/accounts/sign-up/0cb4xmh5vc"
                className="text_button"
              >
                Exness Registration
              </a> */}
              {/* <a
                target="_blank"
                href={exnessInfo?.website_url}
                className="text_button"
              >
                Exness KYC
              </a> */}
            </div>
          </div>
        </div>

        <div className="landingContainer">
          <div className="navSection">
            <div className="logoSection">
              <img src={Logo} />
            </div>
            <div className="signupSection">
              <a
                href={isAuthenticated ? "/auth" : "/auth/login"}
                className="login_button"
              >
                {isAuthenticated ? "Dashboard" : "Log in"}
              </a>
              {!isAuthenticated && (
                <a href="/auth/register" className="signup_button">
                  Sign up
                </a>
              )}
            </div>
          </div>
        </div>
        {/* =============================== */}

        <div className="landingContainer">
          <div className="bannerSection">
            <div className="w_50 pr_15 relative">
              <h1 className="bannerTitle coinsNameBox">Ai forex trading</h1>
              <p className="bannerPara">
                AI in forex trading involves the use of machine learning
                algorithms, natural language processing, and other AI
                technologies to analyze market data, predict price movements,
                and execute trades. These systems can process vast amounts of
                data at high speeds, identifying patterns and trends that may be
                invisible to the human eye.
              </p>
              <p className="mt_20">
                The integration of AI into forex trading is still in its early
                stages, but its potential is immense. As AI technologies
                continue to evolve, we can expect even more sophisticated tools
                and strategies to emerge, further transforming the forex market.
              </p>
              <div className="clearboth"></div>
              <div className="w_full flex flex-wrap items-center mt_20 mb_20">
                {!loading && exnessInfo && (
                  <a
                    //href="https://social-trading.exness.com/a/0cb4xmh5vc/?platform=mobile"
                    href={exnessInfo?.website_url}
                    target="_blank"
                    className="banner_button flex items-center mt_20 mb_20"
                  >
                    <span>Register Now </span>
                    <img className="ml_10" src={Arrowright} />
                  </a>
                )}
                <a
                  href="#FAQ"
                  className="textLink items-center mt_20 mb_20 pl_5"
                >
                  <span>How to Register </span>
                </a>
              </div>

              {/* {!loading && exnessInfo && (
                <div class="marquee-container tobeinvestmainBox">
                  <marquee
                    class=""
                    behavior="scroll"
                    direction="left"
                    scrollamount="5"
                  >
                    <p class="coinsNameBoxRrd">
                      Current running pool:{" "}
                      <span class="text-white">{exnessInfo?.pools}</span>
                    </p>
                   
                  </marquee>
                </div>
              )} */}

              <div className="clearboth mb_15"></div>
            </div>

            {/* ****************************** */}

            <div className="w_50 pl_15 bannerRight relative">
              <div className="radialbg1"></div>

              <img src={Banner} className="relative bannerImgWidth" />
              <div className="flex absolute1 anmiation3">
                <img className="mr_7" src={Dollar} />
                <span>Explore the future of trading</span>
              </div>

              <div className="absolute2 anmiation1">
                <div className="block">
                  <div className="text-center">Total Registration</div>
                  <div className="text-center text-2xl text-white font-800">
                    {exnessInfo.number_of_user}
                  </div>
                </div>

                {/* <div className="flex w_full items_center">
                  <div className="w_50 mt_10 text-center">
                    <div className="mb_10">Income</div>
                    <div className="text-white font-800 text-xl">$5680</div>
                  </div>
                  <div className="w_50 mt_10 text-center">
                    <div className="mb_10">Expense</div>
                    <div className="text-white font-800 text-xl">$6566</div>
                  </div>
                </div> */}
              </div>

              {/* <div className="absolute3 anmiation2">
                <div className="">
                  <div className="bgfad1 text-center mb_15">
                    <div className="mb_10">Profit</div>
                    <div className="text-white font-800 text-xl">$6566</div>
                  </div>

                  <div className="bgfad2 text-center">
                    <div className="mb_10">Balance</div>
                    <div className="text-white font-800 text-xl">$6566</div>
                  </div>
                </div>

                <div>
                  <div className="bgfad3 text-center mb_15">
                    <div className="mb_10">Payout</div>
                    <div className="text-white font-800 text-xl">$6566</div>
                  </div>

                  <div className="bgfad4 text-center">
                    <div className="mb_10">Commision</div>
                    <div className="text-white font-800 text-xl">$6566</div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* =============================== */}
        <div className="landingContainer section polls">
          <Pools data={exnessInfo.pools} />
        </div>
        {/* =============================== */}
        <div className="landingContainer section">
          <div className="flex flex-wrap">
            <div className="w_40 pr_15 relative aiPower">
              <div className="radialbg1"></div>

              <img src={AboutImg} className="relative" />
              <div className="flex absolute4">
                <img class="mr_7" src={AItraing} />
                <span>The power of AI trading</span>
              </div>
            </div>

            <div className="w_60 pl_85 relative">
              <div className="text-2xl font-800  text-white uppercase">
                WIN GOLD MILLIONAIRES
              </div>
              <p className="text-xl coinsNameBox">
                <b>Creating a wide range of millionaires in this system. </b>
              </p>
              <p className="mt_20">
                Welcome to Win Gold Millionaires, a groundbreaking trading
                platform dedicated to{" "}
                <b>creating a wide range of millionaires</b>. As an upcoming
                leader in the financial market, we are committed to developing
                an innovative trading experience that surpasses our competitors.{" "}
                <b>Our vision</b> is clear: to build a strong community of
                traders who will grow with us and achieve remarkable success. ,
                we aim to establish ourselves as the top trading platform in
                future, driven by top-notch technology and a shared passion for
                financial growth. Join us on this exciting journey, and
                together, let's turn dreams into reality and create a new
                generation of millionaires.
              </p>
            </div>
          </div>
        </div>

        {/* =============================== */}
        <div className="landingContainer section">
          <div className="text-3xl mt_15 text-white font-800 mt_15 text-center uppercase">
            What is AI trading
          </div>
          <p className="mt_20 mb_15 text-center w_70 mx-auto">
            AI in forex trading involves the use of machine learning algorithms,
            natural language processing, and other AI technologies to analyze
            market data, predict price movements, and execute trades. These
            systems can process vast amounts of data at high speeds, identifying
            patterns and trends that may be invisible to the human eye.
          </p>
          <p className="mt_20 mb_15 text-center w_70 mx-auto">
            Whether you're a beginner or an experienced trader, our platform
            empowers you to make informed decisions and your trading success.
          </p>

          <div className="clearboth mt_20 mb_20"></div>
          <div className="flex flex-wrap mt_20">
            <div className="w_33 relative aiBox_firstchild">
              <div className="aiBox">
                <div className="radialbg2"></div>

                <div className="relative">
                  <img src={Trading} />

                  <div className="text-xl text-white font-800 mt_15 uppercase">
                    Safe Ways of Trading
                  </div>

                  <p className="mt_15 mb_15">
                    Trading in financial markets offers the potential for
                    significant returns, but it also comes with inherent risks.
                    To succeed and protect your capital, it's essential to adopt
                    safe trading practices. This guide provides strategies and
                    tips to help you trade safely, minimize risks, and maximize
                    returns.
                  </p>
                </div>
              </div>
            </div>
            {/* ************ */}
            <div className="w_33 relative aiBox_secondtchild">
              <div className="aiBox">
                <div className="radialbg2"></div>

                <div className="relative">
                  <img src={Accuracy} />
                  <div className="text-xl text-white font-800 mt_15 uppercase">
                    Achieving Better Accuracy
                  </div>

                  <p className="mt_15 mb_15">
                    In the fast-paced world of trading, accuracy is paramount.
                    Better accuracy in trading means more reliable predictions,
                    fewer losses, and increased profitability. This guide
                    explores various strategies and tools to enhance your
                    trading accuracy, helping you make more informed decisions
                    and improve your overall trading performance.
                  </p>
                </div>
              </div>
            </div>
            {/* ************ */}
            <div className="w_33 relative aiBox_thirdchild">
              <div className="aiBox">
                <div className="radialbg2"></div>

                <div className="relative">
                  <img src={automatic} />

                  <div className="text-xl text-white font-800 mt_15 uppercase">
                    Fully Automatic and Fast Trading
                  </div>

                  <p className="mt_15 mb_15">
                    In the competitive world of trading, speed and automation
                    can be key advantages. Fully automatic and fast trading
                    leverages advanced technologies to execute trades quickly
                    and efficiently, minimizing the impact of human errors and
                    maximizing opportunities. This guide explores the concepts,
                    benefits, and tools associated with fully automatic and fast
                    trading.
                  </p>
                </div>
              </div>
            </div>
            {/* ************ */}
          </div>
        </div>

        {/* =============================== */}
        <div className="landingContainer section">
          <div className="bgcolor1 flex flex-wrap">
            <div className="w_60 pr_15 relative">
              <div className="text-2xl font-800 mb_15 text-white uppercase">
                EXNESS IS OUR JOINT VENTURE SHARE BROKING COMPANY
              </div>
              <p className="text-xl coinsNameBox">
                <b>World top 10 ranking - No.1 trading broker </b>
              </p>
              <p className="mt_20">
                Exness is a multi-asset, tech-focused broker utilizing advanced
                algorithms for enhanced trading conditions
              </p>
              <p className="mt_20">
                Exness set out in 2008 to balance ethics and technology to
                reimagine how the ideal trading experience could be. Today, as a
                leader in the industry of CFD trading, servicing over 800,000
                active traders, we know we’re on the right path.
              </p>
            </div>

            <div className="w_40 pl_15 relative">
              <div className="radialbg1"></div>
              <img src={Exness} className="relative " />
            </div>
          </div>
        </div>

        {/* =============================== */}
        <div className="landingContainer section">
          <div className="text-2xl font-800 mb_15 text-white text-center w-full uppercase">
            Trade Assets Across Global Markets
          </div>
          <p class="mt_20 mb_15 text-center w_70 mx-auto">
            Unlock the potential of global markets and diversify your investment
            portfolio by trading a wide range of assets from around the world.
          </p>

          <div className="flex flex-wrap items-center w-full">
            <div className="w_40 pr_15 relative mt_20">
              <div className="radialbg1"></div>
              <img src={AItrade} className="relative w-full borderRadius16" />
            </div>

            <div className="w_60 pl_15 relative mt_20">
              <p className="text-xl font-800">Currency </p>
              <p className="mt_5 font_15">
                Currency trading, also known as Forex trading, offers a unique
                opportunity to capitalize on the fluctuations in currency
                values.
              </p>
              <p className="text-xl mt_20 font-800">Mettal </p>
              <p className="mt_5 font_15">
                Metal trading offers investors the opportunity to participate in
                one of the most dynamic and essential sectors of the global
                economy. Trading metals can provide diversification and
                potential for significant returns.
              </p>
              <p className="text-xl mt_20 font-800">Commodity </p>
              <p className="mt_5 font_15">
                Commodity trading allows investors to trade in essential goods
                and resources that drive the global economy. From agricultural
                products to energy resources commodities offer a range of
                investment opportunities and diversification benefits.
              </p>
              <p className="text-xl mt_20 font-800">Crypto </p>
              <p className="mt_5 font_15">
                Crypto trading offers investors the chance to engage with one of
                the most innovative and rapidly evolving markets: digital
                currencies. With thousands of cryptocurrencies available, the
                crypto market presents unique opportunities and risks.
              </p>
            </div>
          </div>
        </div>

        {/* =============================== */}

        <div className="landingContainer section">
          <div className="text-2xl font-800 mb_15 text-white text-center uppercase">
            Our Business Plan
          </div>

          <div className="">
            <p className="text-center w_70 mx-auto">
              Our plan is structured to benefit participants at every level,
              ensuring continuous growth and profitability. We offer substantial
              financial rewards through multiple income streams.
            </p>

            <div className="text-xl font-800 mb_20 mt_20 text-white text-center mt_15">
              Types of income
            </div>
            <ul className="conpensationPlan">
              <li>Direct referral income</li>
              <li>Level circle bonus</li>
              <li>Global profit share</li>
              <li>Awards & Rewards</li>
            </ul>
          </div>

          <p className="text-lg text-center">
            <b className="darkGold">
              Direct referral income :{" "}
              <span className="text-xl spanHighlight"> 5% </span>
            </b>
          </p>
          <p className="text-center text-xl font-800 mb_15 text-white text-center uppercase mt_20 pt_20">
            <b>
              Profit level circle bonus : <span className="xl">10 Levels </span>
            </b>
          </p>
          <div className="plan_income_tableHolder">
            <table
              className="table plan_income_table"
              cellpadding="0"
              cellspacing="0"
              border="0"
            >
              <thead>
                <tr>
                  <th>Level</th>
                  <th>Income</th>
                  <th className="text-center">Minimum Investment</th>
                  <th className="text-center">Direct Referral</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td>10%</td>
                  <td className="text-center">200$</td>
                  <td className="text-center">1</td>
                </tr>
                <tr>
                  <td>2</td>
                  <td>8%</td>
                  <td className="text-center">200$</td>
                  <td className="text-center">1</td>
                </tr>
                <tr>
                  <td>3</td>
                  <td>6%</td>
                  <td className="text-center">200$</td>
                  <td className="text-center">2</td>
                </tr>
                <tr>
                  <td>4</td>
                  <td>4%</td>
                  <td className="text-center">200$</td>
                  <td className="text-center">2</td>
                </tr>
                <tr>
                  <td>5</td>
                  <td>2%</td>
                  <td className="text-center">500$</td>
                  <td className="text-center">3</td>
                </tr>
                <tr>
                  <td>6</td>
                  <td>1%</td>
                  <td className="text-center">500$</td>
                  <td className="text-center">3</td>
                </tr>
                <tr>
                  <td>7</td>
                  <td>1%</td>
                  <td className="text-center">1000$</td>
                  <td className="text-center">4</td>
                </tr>
                <tr>
                  <td>8</td>
                  <td>1%</td>
                  <td className="text-center">1000$</td>
                  <td className="text-center">4</td>
                </tr>
                <tr>
                  <td>9</td>
                  <td>3%</td>
                  <td className="text-center">2000$</td>
                  <td className="text-center">5</td>
                </tr>
                <tr>
                  <td>10</td>
                  <td>4%</td>
                  <td className="text-center">2000$</td>
                  <td className="text-center">5</td>
                </tr>
              </tbody>
            </table>
          </div>
          <p className="text-center text-xl">
            <b>
              ID activation fee : 6%{" "}
              <span className="font_13">(investment)</span>{" "}
            </b>
          </p>
          <p className="text-center  text-xl">
            <b>Trading profit : 0.2% - 1% /day</b>
          </p>
        </div>

        {/* =============================== */}

        <div className="landingContainer section">
          <div className="text-2xl font-800 mb_15 uppercase text-white text-center">
            Achievement
          </div>

          <div className="achievmnt">
            <ul>
              <li>
                <div className="rwardText">250$</div>
                <div className="criteriaText">G1-Criteria</div>
                <div className="criterias">
                  <div className="">
                    Direct: <b className="text-white">10000$</b>
                  </div>
                  <div className="">
                    Team: <b className="text-white">20000$</b>
                  </div>
                </div>
                <div className="limitBox">Time limit : 1 month</div>
              </li>
              <li>
                <div className="rwardText">Dubai trip</div>
                <div className="criteriaText">G2-Criteria</div>
                <div className="criterias">
                  <div className="">
                    Direct: <b className="text-white">40000$</b>
                  </div>
                  <div className="">
                    Team: <b className="text-white">40000$</b>
                  </div>
                </div>
                <div className="limitBox">Time limit : 2 month</div>
              </li>
              <li>
                <div className="rwardText">Thailand trip</div>
                <div className="criteriaText">G3-Criteria</div>
                <div className="criterias">
                  <div className="">
                    Direct: <b className="text-white">60000$</b>
                  </div>
                  <div className="">
                    Team: <b className="text-white">60000$</b>
                  </div>
                </div>
                <div className="limitBox">Time limit : 3 month</div>
              </li>
              <li>
                <div className="rwardText">15000$</div>
                <div className="criteriaText">G4-Criteria</div>
                <div className="criterias">
                  <div className="">
                    Direct: <b className="text-white">100000$</b>
                  </div>
                  <div className="">
                    Team: <b className="text-white">200000$</b>
                  </div>
                </div>
                <div className="limitBox">Time limit : 6 month</div>
              </li>
              <li>
                <div className="rwardText">50000$</div>
                <div className="criteriaText">G5-Criteria</div>
                <div className="criterias">
                  <div className="">
                    Direct: <b className="text-white">200000$</b>
                  </div>
                  <div className="">
                    Team: <b className="text-white">1000000$</b>
                  </div>
                </div>
                <div className="limitBox">Time limit : 9 month</div>
              </li>
              <li>
                <div className="rwardText">100000$</div>
                <div className="criteriaText">G6-Criteria</div>
                <div className="criterias">
                  <div className="">
                    Direct: <b className="text-white">500000$</b>
                  </div>
                  <div className="">
                    Team: <b className="text-white">2500000$</b>
                  </div>
                </div>
                <div className="limitBox">Time limit : 12 month</div>
              </li>
            </ul>
          </div>
          <div className="text-xl w-full mt_15 text-center">
            Accumulation plan
          </div>
          <p className="text-center">Team calculation upto 6 level</p>
        </div>
        {/* =============================== */}

        <div className="landingContainer section flex flex-wrap">
          <div className="w_35">
            <div className="relative mt_20">
              <div class="radialbg1"></div>
              <div className="text-3xl font-800 mb_15 text-white relative uppercase">
                Global Profit share
                <br />
                <img className="mt_20 relative" src={ProfitShare} />
              </div>
            </div>
          </div>

          <div className="w_65 pl_60">
            <div className="w-full">
              <ul className="prfitShareUl">
                <li>
                  <div className="profitText"> 01 </div>
                  <div className="text-white uppercase"> G1 </div>
                  <div className="shareText">
                    {" "}
                    <b className="text-white text-xl">0.5%</b> Share{" "}
                  </div>
                </li>
                <li>
                  <div className="profitText"> 02 </div>
                  <div className="text-white uppercase"> G2 </div>
                  <div className="shareText">
                    {" "}
                    <b className="text-white text-xl">1%</b> Share{" "}
                  </div>
                </li>
                <li>
                  <div className="profitText"> 03 </div>
                  <div className="text-white uppercase"> G3 </div>
                  <div className="shareText">
                    {" "}
                    <b className="text-white text-xl">1.5%</b> Share{" "}
                  </div>
                </li>
                <li>
                  <div className="profitText"> 04 </div>
                  <div className="text-white uppercase"> G4 </div>
                  <div className="shareText">
                    {" "}
                    <b className="text-white text-xl">2%</b> Share{" "}
                  </div>
                </li>
                <li>
                  <div className="profitText"> 05 </div>
                  <div className="text-white uppercase"> G5 </div>
                  <div className="shareText">
                    {" "}
                    <b className="text-white text-xl">2.5%</b> Share{" "}
                  </div>
                </li>
                <li>
                  <div className="profitText"> 06 </div>
                  <div className="text-white uppercase"> G6 </div>
                  <div className="shareText">
                    {" "}
                    <b className="text-white text-xl">3%</b> Share{" "}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* =============================== */}

        <Faq />

        {/* =============================== */}

        <div className="footer">
          <div className="landingContainer">
            <div className="footerlogoSection">
              <img src={Logo} />
            </div>

            <p className="mx-auto mt_20 text-center font_13">
              Trading financial markets involves significant risk and is not
              suitable for every investor. This guide provides a comprehensive
              overview of the potential risks associated with trading and offers
              strategies for mitigating these risks. Understanding these
              warnings is crucial for anyone looking to engage in trading
              activities,.
            </p>
            <p className="w_70 mx-auto mt_10 text-center font_13">
              Copyright © 2024 Win Gold Millionaires . All rights reserved.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
